import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Modal, Slide, TextField, Typography } from "@material-ui/core";
import * as React from "react";
import { FC } from "react";

export interface ContactModalProps {
    open: boolean;
    onClose: () => void;
}

export const ContactModal: FC<ContactModalProps> = ({open, onClose}) => {

    return (
        <div>
            <Dialog open={open} onClose={onClose}>
            <DialogTitle>Contact Information</DialogTitle>
            <DialogContent>
                <Typography>Email</Typography>
                <Link href="mailto:matthewtokic01@gmail.com" underline="hover">matthewtokic01@gmail.com</Link>
            </DialogContent>
            <DialogActions>
            <Button onClick={onClose}>Back</Button>
            </DialogActions>
      </Dialog>
    </div>
  );
}
