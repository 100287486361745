import { makeStyles, Typography } from "@material-ui/core";
import * as React from "react";

import { useDencrypt } from "use-dencrypt-effect";

const about=["A Junior Frontend/Backend Developer"]


const useStyles = makeStyles(theme => ({
  headerText: {
    marginLeft: theme.spacing(10),
  }
}));

export const About = () => {
  const {result, dencrypt} = useDencrypt();
  
  const classes = useStyles();
  React.useEffect(() => {
    let i = 0;
    const action = setInterval(() => {
      dencrypt(about[i]);
      i = i === about.length - 1 ? 0 : i + 1;
    }, 2000);

    return () => clearInterval(action);
  }, []);
  
  
  return <div>
     <Typography variant="h6" className={classes.headerText}>{result}</Typography>
  </div>

};

export default About;