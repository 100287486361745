import { makeStyles, Typography } from "@material-ui/core";
import * as React from "react";

import { useDencrypt } from "use-dencrypt-effect";

const tag=["I make things :)"]


const useStyles = makeStyles(theme => ({
  headerText: {
    marginLeft: theme.spacing(10),
    
  }
}));

export const Tag = () => {
  const {result, dencrypt} = useDencrypt();
  
  const classes = useStyles();
  React.useEffect(() => {
    let i = 0;
    const action = setInterval(() => {
      dencrypt(tag[i]);
      i = i === tag.length - 1 ? 0 : i + 1;
    }, 5500);

    return () => clearInterval(action);
  }, []);
  
  
  return <div>
     <Typography variant="h6" className={classes.headerText}>{result}</Typography>
  </div>

};

export default Tag;