import { AppBar, Button, IconButton, makeStyles, Toolbar, Typography } from "@material-ui/core";
import React, { useState } from "react";
import MenuIcon from '@material-ui/icons/Menu';
import { ContactModal } from "./ContactModal";


const Navbar = () => {

    const useStyles = makeStyles((theme) => ({
        root: {
          flexGrow: 1,
        },
        menuButton: {
          marginRight: theme.spacing(2),
        },
        title: {
          flexGrow: 1,
        },
      }));

      //State
      const [open,setOpen] = useState<boolean>(false);
      const handleClose = () => {
          setOpen(!open)
      }
      const handleContact = () => {
        setOpen(true);
      }
      const classes = useStyles();
    return (
        
        <div className={classes.root}>
        <AppBar style={{ background: 'transparent', boxShadow: 'none'}} position="static">
          <Toolbar>
    
            <Typography variant="h5" className={classes.title}>
              MT.
            </Typography>
       
            <Button 
              color="inherit"
              onClick={()=>window.open("https://www.linkedin.com/in/matthew-tokic-0b9357216/")}
              >
                linkedin
            </Button>
            <Button 
              color="inherit"
              onClick={()=>window.open("https://github.com/matttokic")}
              >
                github
            </Button>
            {/* <Button 
              color="inherit"
              onClick={handleContact}
            >contact
            </Button> */}
          </Toolbar>
        </AppBar>
        <ContactModal
          open={open}
          onClose={handleClose} />
      </div>
      );
}

export default Navbar