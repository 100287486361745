import React from 'react';
import Navbar from './components/Navbar';
import About from './components/About'
import Name from './components/Name';
import Tag from './components/Tag';


function App() {
  return (
    <div className="App">
      <Navbar />
      <Name />
      <About  />
      <Tag />
    </div>
  );
}

export default App;
